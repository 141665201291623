<template>
    <div>
        <top></top>
        <div style="width: 1200px; margin: 0 auto">
            <div class="nav_tit">
                <span>首页</span>
                <template v-if="currentNav">
                    <img src="../assets/icon_arrow1.png" />
                    <router-link style="color: #555" :to="{ name: 'WebLink', query: { id: currentNav.id } }">
                        <span>{{ currentNav.name }}</span>
                    </router-link>
                </template>
            </div>
        </div>
        <div class="common_box about_box" style="padding-top: 0">
            <ul class="about_fl">
                <router-link :to="{ name: 'WebLink', query: { id: item.id } }" replace v-for="(item, index) in navList"
                    :key="index" style="color: #555">
                    <li :class="['about_nav_list', item.id == num ? 'active' : '']" style="cursor: pointer"
                        @click="handleClick(item)">
                        <span>{{ item.name }}</span>
                    </li>
                </router-link>
            </ul>
            <div class="about_fr">
                <ul class="file_box">
                    <img src="../assets/zz.jpg" v-if="currentNav.id == 1">
                    <img src="../assets/sp.jpg" v-if="currentNav.id == 2">
                </ul>
            </div>
        </div>
        <bottom></bottom>
        <right></right>
    </div>
</template>

<script>
import Top from "../components/Top";
import Bottom from "../components/Bottom";
import Right from "../components/Right";

export default {
    components: { Right, Bottom, Top },
    data() {
        return {
            navList: [{ name: '营业执照', id: 1 }, { name: '仅销售预包装食品备案', id: 2 }],
            num: 1,
            loading: false,
        };
    },
    computed: {
        currentNav() {
            return this.navList.find((item) => item.id == this.num) || {};
        },
    },
    mounted() {
        this.num = this.$route.query.id;
        console.log(this.$route.query.id)
    },
    methods: {
        handleClick(item) {
            this.num = item.id;
        },
    },
    watch: {
        '$route'(to, from) {
            if (to.path == "/weblink") { 
                this.num = this.$route.query.id
            }
        }
    },
};
</script>

<style lang="less">
@import "../common/public.less";

body {
    background: #fff;
}

.top_box {
    border-bottom: 1px solid #ececec;
}

.active {
    color: red !important;
}

.about_box {
    .disf;
    padding: 40px 0 50px;
    font-size: 14px;

    .about_fl {
        width: 200px;
        text-align: center;
        border: 1px solid #ececec;

        .about_nav_list {
            padding: 20px 0;
            font-size: 16px;
            border-bottom: 1px solid #ececec;
            .disa;
            justify-content: center;

            &:last-child {
                border-bottom: none;
            }

            img {
                width: 15px;
                height: 15px;
                margin-left: 10px;
            }
        }

        .about_acitve {
            background: #faf6f6;
            color: @color;
        }
    }

    .about_fr {
        width: 970px;
        height: auto;
        border: 1px solid #ececec;

        .file_box {
            padding: 0 30px;

            img {
                width: 100%;
            }
        }
    }
}
</style>